<template>
  <div class="track">
    <!-- 页面载入后，调用init函数 -->
    <!-- 定义地图显示容器 -->
    <div id="container"></div>
  </div>
</template>
<script
    charset="utf-8"
    src="https://3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js"
></script>
<script
    charset="utf-8"
    src="https://map.qq.com/api/js?v=2.exp&key=LDIBZ-UYHWR-D66WD-WLGIT-WU5DJ-TEFZJ"
></script>
<script>
export default {
  name: 'track1',
  components: {},
  data() {
    return {};
  },
  created() { },
  mounted() {
    this.initMap();
    // this.ad()
  },
  methods: {
    initMap() {
      var center = new window.TMap.LatLng(28.22778, 112.93886);
      // 初始化地图
      var map = new window.TMap.Map('container', {
        rotation: 20, // 设置地图旋转角度
        pitch: 30, // 设置俯仰角度（0~45）
        zoom: 12, // 设置地图缩放级别
        center: center, // 设置地图中心点坐标
        viewMode: '2D', // 设置地图视图模式
        showControl: false // 控制地图控件的显示与隐藏
      });
      console.log(map + '');
    },
    // 设置地图中心点事件
    setCenter() {
      window.map.setCenter(new window.TMap.LatLng(28.23529, 112.93134)); // 坐标为天安门
    }
  }
};
</script>
<style lang="less" scoped>
.track {
  //   flex: 1;
  height: 100%;
  width: 100%;
  // background-color: pink;
  #container {
    width: 100%;
    height: 100%;
  }
}
</style>
